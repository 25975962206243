html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: "Lato", sans-serif;
  color: #134;
  font-weight: 300;
}

.unsetLink {
  text-decoration: none;
  color: unset;
  font-weight: unset;
}

.unsetLink:hover {
  color: #0139;
  opacity: 0.7;
  text-shadow: 0px 0px 10px 10px #ade1;
}

hr {
  background-color: #adea;
  box-shadow: 0px 0px 10px 10px #ade1;
}

.app-cont {
  border-top: 1px solid #ade3;
  // max-width: 1024px;
  height: 92vh;
  overflow: auto;
}

.app-cont::-webkit-scrollbar {
  width: 0.4em;
}

.app-cont::-webkit-scrollbar-thumb {
  background-color: #0134;
  outline: 1px solid #ade1;
  border-radius: 0.2rem;
}

.vh100 {
  height: 100vh;
}

.vhmin100 {
  min-height: 100vh;
}

.h-100 {
  height: 100%;
}

.h-min-100 {
  min-height: 100%;
}

.vh90 {
  height: 90vh;
}

.vh10 {
  height: 10vh;
}

.vh8 {
  height: 8vh;
}
.card {
  background-color: #f8f5f4;
  // border: 1px solid #f3f2f1aa;
  box-shadow: 0px 5px 10px 2px #99aabb15;
  border-radius: .65rem;
  //min-height: 20rem;
}

.card-top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.my-border {
  border: 1px solid #ade5;
}
.my-light-shadow {
  box-shadow: 0px 0px 10px 5px #ade1;
}

.iconSizeSmall {
  width: 30;
  height: 30;
}
.h90 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.xl-icon {
  font-size: 2.4rem;
}

.xxl-icon {
  font-size: 3rem;
}

.super-xl-icon {
  font-size: 5rem;
}

.chat-icon {
  filter: drop-shadow(0 0 0.75rem #132);
  transform: translate((0px, -4px));
}
.chat-icon-cont {
  background: linear-gradient((135deg, #2f75c5, #1b4474));
  border-radius: 50%;
  -webkit-transform: scaleX(-1);
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scaleX(-1);
}

.lg-icon {
  font-size: 1.9rem;
}

.md-icon {
  font-size: 1.5rem;
}

.sm-icon {
  font-size: 0.8rem;
}

.size12 {
  font-size: 0.7rem;
}

.size13 {
  font-size: 0.75rem;
}

.size14 {
  font-size: 0.8rem;
}

.bg-dark-blue-grey {
  background-color: #3453;
}

.size14pt5 {
  font-size: 0.85rem;
}

.size14pt8 {
  font-size: 0.86rem;
}

.size15 {
  font-size: 1rem;
}

.size16 {
  font-size: 1.1rem;
}

.z-top {
  z-index: 999;
}

.bg-my-light {
  background-color: #ade2;
}

.bg-extra-light {
  background-color: #aadfe008;
}

.bg-extra-light-2 {
  background-color: #096efd11;
}

.bg-my-dark {
  background-color: #101526;
}
.bg-my-dark-light {
  background-color: #50556611;
}

.bg-green {
  background-color: #1adb80;
}

.colorDark {
  color: #345;
}

.weight200 {
  font-weight: 200;
}

.weight300 {
  font-weight: 300;
}

.weight400 {
  font-weight: 400;
}

.weight500 {
  font-weight: 500;
}

.colorWhite {
  color: #fff;
}

.colorLight {
  color: #446690;
}

.user-info-cont {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}

.small-card {
  width: 48rem;
}

.small-card-img {
  width: 10rem;
  margin-top: 1rem;
  align-self: center;
}

.smaller-card {
  width: 28rem;
}

.smaller-card-img {
  width: 5rem;
  margin-top: 1rem;
  align-self: center;
}

.app-drawer {
  height: 100vh;
  padding-bottom: 2rem;
  overflow: auto;
}

.app-drawer::-webkit-scrollbar {
  width: 0.4em;
}

.app-drawer::-webkit-scrollbar-thumb {
  background-color: #0134;
  outline: 1px solid #ade1;
  border-radius: 0.2rem;
}

.mySticky {
  position: absolute;
  z-index: 999;
  width: 80%;
  // background-color: #e3f4f9;
  height: 100%;
  transition: 2s;
}

.bd-1 {
  border-bottom: 1px solid #ade3;
}

.my-light-border {
  border: 1px solid #ade5;
}

.capitalize {
  text-transform: capitalize;
}
.d-flex-row {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}

.d-flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.justify-end {
  align-self: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.truncate {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncated {
  width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.long-trunc {
  width: 30vw;
  max-height: max-content;
  overflow: hidden;
  white-space: normal;
  line-height: 1.2rem;
  text-overflow: ellipsis;
}

.truncated-sm {
  width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.crumbLink {
  text-decoration: none;
}

.form-label {
  font-weight: 400;
}

.myModalBase {
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #3455;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myModal {
  z-index: 999;
  width: 35rem;
}

.mbMinus10 {
  margin-bottom: -100px;
}

.py-1pt5 {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.yesScroll {
  overflow: scroll;
}

.btn-no-outline:focus {
  outline: none;
  box-shadow: none;
}

.br-10 {
  border-radius: 0.5rem;
}

.text-shadow-sm {
  text-shadow: 0px 0px 15px #0132;
}

.translucent {
  background: linear-gradient(
    160deg,
    #000e 10%,
    #0007 30%,
    #56de6745,
    #de566745,
    #5667de45
  );
}
.text-left {
  text-align: left;
}

.logo-img {
  filter: drop-shadow(0 0 0.75rem #cef);
}

.form-label {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.my-badge {
  background-color: #000;
  color: #eee;
}

.my-dark-shadow {
  box-shadow: 0px 0px 10px #1234;
  z-index: 100;
}

.max-width-28rem {
  max-width: 28rem;
}

.max-width-24rem {
  max-width: 24rem;
}

.max-width-10rem {
  max-width: 10rem;
}

.min-width-3rem {
  min-width: 3rem;
}

.text-red {
  color: #f93465;
}

.red-border {
  border-color: #f93465;
}

.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}

.z-top {
  z-index: 999;
}

.bg-transparent {
  background-color: #fff0;
}

.stat-percent-cont {
  width: 2rem;
  height: 2rem;
}

.fw-extra-bold {
  font-weight: 700;
}

.fw-semi-bold {
  font-weight: 500;
}

tspan {
  font-weight: 400;
}

// .my-app-drawer-shadow {
//   box-shadow: 0px 0px 10px #9ab3
// }
.br-1 {
  // border-right: 2px solid #ade3;
}

.dark-card {
  background-color: #345;
  color: #fefefe;
}

.bg-none {
  background: #fff0;
  border-radius: 1rem;
}

.my-input-group {
  padding: 1rem 0.6rem;
}

.fs-italic {
  font-style: italic;
}

.max-width-60rem {
  max-width: 60rem;
}

.my-email-badge {
  color: #000;
}

.my-green-bg {
  background-color: #33f94522;
  color: #22c935;
  border: 1px solid #33f945;
}

.form-error-text {
  color: #f93465;
}

.table-head-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #9ab4;
  margin-bottom: 0.1rem;
  align-items: center;
  width: 100%;
}

.table-head-col {
  flex: 1;
  padding: 0.5rem 0.5rem;
  font-weight: 500;
  min-width: max-content;
  overflow: hidden;
  text-align: left;
}

.table-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  border-top: 1px solid #9ab0;
  border-bottom: 1px solid #9ab0;
  width: 100%;
  // outline: 2px solid #3455;
}

.table-row:not(.no-hover):nth-child(odd) {
  background-color: #fafafa;
}

.table-row:not(.no-hover):nth-child(even) {
  background-color: #fcfcfc;
}

.table-row:not(.no-hover):hover {
  background: #29ef7d05;
  // color: #fefefe !important;
  box-shadow: 0px 0px 5px 5px #9ab3;
  border-top: 1px solid #9ab5;
  border-bottom: 1px solid #9ab5;
  z-index: 1;
  cursor: pointer;

}

.table-col {
  flex: 1;
  padding: 0.1rem 0.3rem;
  font-weight: 500;
  overflow: hidden;
  // text-align: center;
  text-align: left;
  // outline: 2px solid #f455;
}

.table-col * {
  overflow-wrap: break-word;
  max-width: 100%;
  min-height: max-content;
  overflow: hidden;
  text-align: left;
  line-height: 1.4;
  white-space: normal;
  text-overflow: ellipsis;
}

// .table-head-row .table-head-col:last-of-type {
//   text-align: center !important;
//   // display: none;
// }

// .table-row .table-col:last-of-type {
//   text-align: center !important;
//   // display: none;
// }

.table-head-row .table-head-col:first-of-type {
  padding-left: 1rem;
}

.table-row .table-col:first-of-type {
  padding-left: 1rem;
}

.calender-head-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #9ab1;
  margin-bottom: 0.5rem;
  align-items: center;
  width: 100%;
}

.calender-head-col {
  flex: 1;
  padding: 0.5rem 0.1rem;
  font-weight: 500;
  min-width: max-content;
  overflow: hidden;
  text-align: center;
}

.calender-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  width: 100%;
  // outline: 2px solid #3455;
}

.calender-col {
  width: 14.2857143%;
  padding: 0.3rem 0rem;
  font-weight: 500;
  overflow: hidden;
  min-width: max-content;
  text-align: center;
  // outline: 2px solid #f455;
}

.flex-zero {
  flex: 0;
}

.lh-1 {
  line-height: 1;
}

.bd-dark-1 {
  border-bottom: 1px solid #3456;
}

.bg-light-dark {
  background-color: #345a;
}

.page-item {
  cursor: pointer;
}

.page-item.disabled {
  cursor: default;
}

.no-vert-pad {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.max-width-24rem {
  max-width: 24rem;
  min-width: 18rem;
}

.min-h-200px {
  min-height: 200px;
}

.text-and-border-blue {
  border: 1px solid #9ab4;
  background-color: #fefefe;
  color: #123;
}

.bg-super-light {
  background: radial-gradient(ellipse at top, #fafafe50, #efffff50),
    radial-gradient(ellipse at bottom, #efffff50, #fafafe50);
}

.flex-three {
  flex: 3;
}

.flex-two {
  flex: 2;
}

.flex-one {
  flex: 1;
}

.flex-1pt5 {
  flex: 1.5;
}

.flex-pt5 {
  flex: 0.5;
}

.uppercase {
  text-transform: uppercase;
}

.my-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #21252822;
  padding: 1rem;
  z-index: 8;
}

.my-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #21252822;
}

.my-modal .modal-content {
  background: radial-gradient(ellipse at top, #f7fef9, #e7fae9),
    radial-gradient(ellipse at bottom, #e7fae9, #f7fef9);
}

.desc-cont {
  margin-top: -1rem;
}
.btl-1 {
  border-bottom: 1px solid #9ab3;
  border-top: 1px solid #9ab1;
  margin-top: 1rem;
}

input:disabled {
  background-color: #fff0 !important;
  color: #212528 !important;
}
.form-check-input:checked:disabled {
  background-color: #0d6efd !important;
}

.form-check-input:checked:disabled {
  background-color: #0d6efd !important;
}

.form-check-label {
  color: #212528 !important;
}

select:disabled {
  background-color: #fff0 !important;
}
textarea:disabled {
  background-color: #fff0 !important;
}

.multiselect-container .search-wrapper {
  background-color: #fff;
}

.multiselect-container.disable_ms .search-wrapper {
  background-color: #fff0;
}


.no-trunc {
  overflow-wrap: unset;
  overflow: unset;
  text-overflow: unset;
}

.h-max-content {
  height: 200px;
}

.v-hidden {
  visibility: hidden;
}

.text-light-2 {
  color: #ccc
}

.btn-my-dark {
  background: #101526a0;
}

.bt-1 {
  border-top: 1px solid #9ab2;
}

@media print {
  div {
    break-inside: avoid;
  }
}

.time-card {
  background-color: #fff;
  border-radius: .5rem;
}

.lh-1pt25 {
  line-height: 1.25;
}

.small-hr-light {
  width: 10rem;
}